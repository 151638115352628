@import 'components/global.scss';

.base {

  display: block;
  width: 22%;
  height: auto;
  margin: 0 auto $scale2;
  outline: none;

}

.noMargin {
  margin: 0!important;
}