@import 'components/global.scss';

.toolTip {
	width: '300px !important';
  white-space: pre-wrap;
  overflow: hidden;
}

.adp-tooltip--component {
  text-transform: none;
  max-width: 200px;
}