@import 'components/global.scss';

.table {

  width: 100%;
  border-spacing: 0;
  margin-bottom: -$scale2;
  border-collapse: separate;

}

.thead {

  // display: none;
  font-weight: 600;

  @media (min-width: $mobile) {

    display: table-header-group;

  }
}

.th {

  text-align: center !important;
  outline: none;
  text-transform: capitalize;
  padding: $scale $scale1 $scale1;
  border-bottom: 1px dotted darken($c-border, 3%);
  color: #0f265c;

  &:first-child {

    padding-left: 0;

  }

  &:last-child {

    padding-right: 0;

  }
}


.body {

  tr:last-child td {

    border-bottom: 0;

  }
}

.cell {
  text-align: center;
  // float: left;
  // width: 100%;
  padding: $scale1;
  // margin-bottom: $scale-1;

  &:first-child {

    padding-left: 0;

  }

  &:last-child {

    padding-right: 0;
    padding-bottom: $scale;
    border-bottom: 1px solid $c-border;

  }

  @media (min-width: $mobile) {

    float: none;
    width: auto;
    margin-bottom: 0;
    // padding: $scale1;
    border-bottom: 1px solid $c-border;

  }
}

span.badge {

  margin-left: 0;

}

.search {

  // margin-bottom: $scale;

  @media (min-width: $mobile) {

    margin-bottom: $scale-3;

  }
}

.actions {

  text-align: left;
  // float: left;
  clear: both;
  margin-bottom: $scale-4;
  white-space: nowrap;
  border-bottom: 1px solid $c-border;

  @media (min-width: $mobile) {

    float: none;
    clear: none;
    text-align: right;
    margin-bottom: 0;

  }
}

.actionButton {

  display: inline-block;
  opacity: 0.9;
  white-space: nowrap;
  top: -0.3em;
  margin-right: $scale-3;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out;

  &:last-child {

    margin-right: 0;

  }
}

.loading {

  position: relative;
  padding: $scale5 0;

}

.empty {

  padding: $scale 0;

}