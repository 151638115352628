@import 'components/global.scss';

.centerImageContainer {
  width: 100%;
  height: 50%;
  margin: 20px 0px;
}

.imgHome {
  width: 95%;
  height: 100%;
  max-width: 100%;
  border-radius: 20px;
}

.mainContainer {
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcomeTitle {
  font-size: 32px;
  font-weight: 700;
  color: #0f265c;
  margin: 10px 0px;
}

.textTitle {
  font-size: 28px;
  color: #0f265c;
  margin: 10px 0px;
  font-weight: 500;
}

.textCatalog {
  font-size: 24px;
  color: #0f265c;
  margin: 10px 0px;
  font-weight: 500;
  text-align: center;
}

.textCatalogPercent {
  font-size: 20px;
  color: #0f265c;
  margin: 10px 0px;
  font-weight: 500;
  text-align: center;
}

.section {
  padding: 0px 0px;

  @media only screen and (min-width: 769px) {
    padding: 0px 100px;
  }

}