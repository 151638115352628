@import 'components/global.scss';

.download {

  background-color: #0f265c;
  color: white;
  padding: $scale $scale5;
  border-radius: $radius;
}

.blockMenu {
  @media only screen and (min-width: 768px) {
    width: 80%;
    height: 25%;
    background: #0f265c;
    position: fixed;
    left: 76.16px;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    border-radius: 20px 20px 0px 0px;
    z-index: 0;
  }
  @media only screen and (min-width: 992px) {
    height: 50%;
  }
}

.mainContainer {
  height: 87vh;
}

.boxContainer {
  width: 100%;
  height: 250px;

  @media only screen and (min-width: 768px) {
    z-index: 1;
    height: 350px;
    width: 400px;
    font-size: 25px !important;
  }
}