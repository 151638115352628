@import 'components/global.scss';

.horizontalLine {
  border: 1px solid #0f265c;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;

  td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }

  th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }

  .tg73oq {
    background-color: #f7f7f8;
    border-color: white;
    border-style: solid;
    border-width: 3px;
    text-align: center;
    vertical-align: top;
    font-weight: 600;
    color: #0f265c;
  }

  .tg73oqWhite {
    background-color: transparent;
    border-color: white;
    border-style: solid;
    border-width: 3px;
    text-align: center;
    vertical-align: top;
  }

  .tg0pky {
    border-color: white;
    text-align: right;
    vertical-align: top;
    border-bottom-color: #eaecf6;
    width: 105px;
  }

  .tg0pkyHead {
    border-color: white;
    text-align: right;
    vertical-align: top;
    color: #0f265c;
    font-weight: 600;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #0f265c;
  border-radius: 6px;
}

/* Customize the label (the container) */
.containeraCheckBox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containeraCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containeraCheckBox:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containeraCheckBox input:checked~.checkmark {
  background-color: #0f265c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containeraCheckBox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containeraCheckBox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ldsDualRing {
  margin: auto;
  width: 11%;
  padding: 10px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0f265c;
  border-color: #0f265c transparent #0f265c transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}