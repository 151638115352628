@import 'components/global.scss';

.background {
  
  // background: rgb(16,38,92);
  // background: linear-gradient(0deg, rgba(16,38,92,1) 0%, rgba(16,38,92,1) 59%, rgba(255,255,255,1) 59%, rgba(255,255,255,1) 100%);

}

.center {

  display: flex;
  justify-content: space-evenly;

}

.threeItems {

  margin: 0 15% 0 15% !important;

}

.twoItems {

  margin: 0 25% 0 25% !important;
  
}

.title {

  color: #10265c;
  text-align: center;
  position: relative;
  width: auto;
  z-index: 3;
  background: white;
  padding: $scale1;
  font-weight: 800;
  // margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;
  font-size: $scale4;

  @media (max-width: $mobile){

    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale;
    border: none;

  }

  @media (min-width: $mobile){

    h1 {

      font-size: $scale1;
      
    }
  }
}