@import 'components/global.scss';

.download {

  background-color: #0f265c;
  color: white;
  padding: $scale $scale5;
  border-radius: $radius;
}

.blockMenu {
  @media only screen and (min-width: 768px) {
    width: 80%;
    height: 25%;
    background: #0f265c;
    position: fixed;
    left: 76.16px;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    border-radius: 20px 20px 0px 0px;
    z-index: 0;
  }

  @media only screen and (min-width: 992px) {
    height: 50%;
  }
}

.mainContainer {
  height: 87vh;
}

.boxContainer {
  width: 100%;
  height: 250px;

  @media only screen and (min-width: 768px) {
    z-index: 1;
    height: 350px;
    width: 400px;
    font-size: 25px !important;
  }
}

.ldsDualRingContainer {
  display: flex;
  justify-content: center;
}

.ldsDualRing {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 13em;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0f265c;
  border-color: #0f265c transparent #0f265c transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

.dualRingPCL {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
}

.dualRingPCL:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0f265c;
  border-color: #0f265c transparent #0f265c transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

label {
  color: #0F265C;
}

.input {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
}

.input_number {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  font-family: Roboto, sans-serif !important;
  font-weight: 200;
}

.inputState {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  width: 100%;
}

.submit {
  background: #0F265C 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  color: #FFFFFF;
  text-align: center;
  width: 204px;
  height: 60px;
  cursor: pointer;
  font: normal normal bold 20px/20px Raleway;
}

.submit:hover {
  background: #00369C 0% 0% no-repeat padding-box;
}

.check input[type=checkbox] {
  width: 25px;
  height: 25px;
  accent-color: #00369C;
}

.title {
  font: normal normal bold 25px/45px Raleway;
  letter-spacing: 0px;
  color: #0F265C;
  opacity: 1;
  // padding-bottom: 25px;
}

.titleContainer {
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.fontRequired {
  font-size: 25px;
  font-weight: 800;
  vertical-align: middle;
}

.containerInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20;

  @media only screen and (min-width: 992px) {
    width: 25%;
  }
}

.containerWithDateInput {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fileInput {
  color: white !important;
  background-color: white !important;
}

.prefix {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.input_number_with_prefix {
  // padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  font-family: Roboto, sans-serif !important;
  font-weight: 200;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  // margin-bottom: 0;
  display: table-cell;
  padding: 6px 12px;
  border: 1px solid #ccc;
  margin: 0;
  z-index: 0;
}

.prefixGroup {
  position: relative;
  display: table;
  border-collapse: separate;
}

.sufixGroup {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}