@import 'components/global.scss';

.base {

  background-color: #eaf7fb;
  position: relative;  
  z-index: 1;
  // margin-bottom: $scale2;
  margin: 50px 50px 50px 50px;
  border-radius: $radius5;
  width: 350px;
  height: 200px;

  > h2 {

    font-weight: 700;
    margin: $scale2 0 0;

  }

  &:last-of-type {

    margin-bottom: 0;

  }
}

.header {

  position: relative;
  overflow: hidden;
  text-align: left;
  color: $c-text;
  padding-bottom: $scale1;
  // margin-bottom: $scale3;
  border-bottom: 1px dotted $c-border;

}

.title {

  color: #282828 !important;
  float: left;
  margin: 0;
  font-size: $scale1;
  font-weight: 800;

}

.center {

  margin-left: auto;
  margin-right: auto;

}

.shadow {

  box-shadow: 0em 0.15em 0.4em rgba(0, 0, 0, 0.05);

}

.loading {

  min-height: $scale13;

}

.restrictWidth {

  @media (min-width: $mobile){

    max-width: $scale15;

  }
}

.padding {

  padding: $scale3;

}

.last {

  margin-bottom: 0;

}