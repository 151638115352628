.ldsDualRing {
  margin: auto;
  width: 11%;
  padding: 10px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0f265c;
  border-color: #0f265c transparent #0f265c transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

.roboto {
  font-family: Roboto, sans-serif !important;
  font-weight: 200;
}

label {
  color: #0F265C;
  margin-bottom: auto;
}

.title {
  font: normal normal bold 25px/45px Raleway;
  letter-spacing: 0px;
  color: #0F265C;
  opacity: 1;
  padding-bottom: 25px;
}

.divider {
  height: 2px;
  background-color: #0f265c;
  margin: 15px 0px;
}

.input {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
}

.input_number {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  font-family: Roboto, sans-serif !important;
  font-weight: 200;
}

.input_numberFinanciero {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  font-family: Roboto, sans-serif !important;
  font-weight: 200;
  width: 100%;
}

.inputNoUppercase {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
}

.textArea {
  padding: 5px 8px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
}

.select {
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000008;
  border-radius: 5px;
  opacity: 1;
  min-height: 53px;
  padding: 5px 8px;
  text-transform: uppercase;
}

.submit {
  background-color: #0F265C !important;
  border-radius: 6px;
  opacity: 1;
  color: #FFFFFF;
  text-align: center;
  width: 204px;
  height: 60px;
  cursor: pointer;
  font: normal normal auto 20px/20px Raleway;
}

.submit:hover {
  background: #00369c !important;
}

.buttonPosition {
  position: relative;
  z-index: 3;
  margin-top: -40px;
  padding-bottom: 10px;

  @media only screen and (min-width: 768px) {

    margin-top: -75px;
    padding-bottom: 50px;
    padding-left: 45px;
  }
}

.paddingLeft {
  padding-left: 0px;

  @media only screen and (min-width: 992px) {
    padding-left: 40px;
  }
}

.textTitle {
  font-size: 28px;
  color: #0f265c;
  margin: 10px 0px;
  font-weight: 500;
}

.textSubTitle {
  font-size: 12px;
  color: #3c4761;
  margin: 10px 0px;
  font-weight: 500;
}

.prioriTextTitle {
  font-size: 32px;
  color: #0f265c;
  margin: 10px 0px;
  font-weight: 700;
  text-align: center;
}

.containerInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20;

  @media only screen and (min-width: 992px) {
    width: 25%;
  }
}

.containerWithDateInput {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.containerStepTwo {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.subContainerStepTwo {
  padding: 0px 15px;
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 30%;
  }
}

.containerOne {
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 40%;
  }
}

.containerTwo {
  width: 100%;
  display: flex;

  @media only screen and (min-width: 992px) {
    width: 60%;
    display: flex;
  }
}

.mainContainerPlan {
  width: 50%;

  @media only screen and (min-width: 1025px) {
    width: 100%;
  }
}

.containerNamePlan {
  background-color: #00369c;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    width: 320px;
  }
}

.containerValuePlan {
  background-color: #95d4e9;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    width: 320px;
  }
}

.mainContainerFormWithBgLightBlue {
  background-color: #eaf7fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* This centers children vertically */
  justify-content: center;
  /* This centers children horizontally */

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.mainContainerFormWithBgTransparent {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* This centers children vertically */
  justify-content: center;
  /* This centers children horizontally */

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.conatinerStepThreeValues {
  width: 100%;

  @media only screen and (min-width: 900px) {
    width: 50%;
  }
}

.conatinerStepThreeValuesFlex {
  width: 100%;
  display: flex;

  // @media only screen and (min-width: 768px) {
  //   width: 50%;
  // }
}

.containerNamePlanThree {
  background-color: #00369c;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    width: auto;
  }
}

.containerValuePlanThree {
  background-color: #95d4e9;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  border-radius: 10px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    width: auto;
  }
}

.containerValuePlanThreeNoColor {
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  border-radius: 10px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    width: auto;
  }
}

.mainContainerStepThree {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 900px) {
    display: flex;
    flex-direction: row;
  }
}

.mainPadding {
  padding: 0px;

  @media only screen and (min-width: 900px) {
    padding: 0px 15px;
  }
}

.priceInputSection {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
  }
}

.qualificationInputSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media only screen and (min-width: 1440px) {
    flex-direction: row;
  }
}

.mainContainerPriceClienteOne {
  display: flex;
  padding: 0px;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    display: flex;
    padding: 40px 0px 0px 40px;
    width: 100%;
    flex-direction: row;
  }
}

.mainContainerPriceClienteOneLeft {
  font-size: 20px;
  padding-left: 0px;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    padding-left: 100px;
    width: 50%;
  }
}

.mainContainerPriceClienteOneRight {
  display: flex;
  padding-top: 40px;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    display: flex;
    padding: 40px 0px 0px 40px;
    width: 100%;
    // flex-direction: row;
  }
}

.mainContainerPriceClienteTwo {
  display: flex;
  padding: 0px;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    padding: 0px 15px;
    flex-direction: row;
  }
}

.mainContainerPriceClienteTwoGeneral {
  width: 100%;

  @media only screen and (min-width: 769px) {
    width: 33%;
    padding: 0px 15px;
  }
}

.mainContainerPriceClienteTwoNamePlan {
  width: 70%;
  background-color: #0f265c;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
}

.mainContainerPriceClienteTwoValuePlan {
  width: 30%;
  background-color: #0f265c;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.marginText {
  margin: 10px 10px 10px 0px;

  @media only screen and (min-width: 769px) {
    margin: 10px;
  }
}

.formOnline {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.formOnlineleft {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.formOnlineRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  align-items: center;

  @media only screen and (min-width: 426px) {
    align-items: flex-end;
  }
}

.inputFormOnlineleft {
  width: 20%;
  padding: 10px;
  display: flex;

}

.inputFormOnlineRight {
  width: 80%;
  padding: 10px;
  display: flex;

}

.aditionalDocuments {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.textaditionalDocuments {
  padding: 10px;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    width: 40%;
  }
}

.buttonaditionalDocuments {
  padding: 10px;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    width: 5%;
  }
}

.notificationaditionalDocuments {
  padding: 10px;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    width: 35%;
  }
}

.formaditionalDocuments {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 1024px) {
    width: 20%;
  }
}

.customFileInput::-webkit-file-upload-button {
  visibility: hidden;
  width: 100% !important;
}

.customFileInput::before {
  content: 'Selecciona el archivo';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  // width: 100%;
}

.customFileInput:hover::before {
  border-color: black;
}

.customFileInput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.containerOtp {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.containerOtpOneTitle {
  width: 100%;
  display: flex;

  @media only screen and (min-width: 1024px) {
    width: 25%;
  }
}

.containerOtpOneButton {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  @media only screen and (min-width: 1024px) {
    width: 50%;
  }
}

//Inicio Formularios
.inputGeneralContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1370px) {
    flex-direction: row;
  }
}

.form {
  padding-left: 0px;

  @media only screen and (min-width: 1024px) {
    padding-left: 20px;
  }
}

.mainContainerForm {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px 20px 20px;

  @media only screen and (min-width: 1024px) {
    width: 33%;
  }
}

.inputContainerSecond {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 33%;
  }
}

.inputContainerHalf {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 50%;
  }
}

.inputContainerQuarter {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 25%;
  }
}

.inputContainerfifth {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 20%;
  }
}

.inputContainer35 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 35%;
  }
}

.labelAccNumber {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    width: 35%;
  }
}

.inputContainer75 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 75%;
  }
}

.inputPreguntas {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

// Formulario financiero
.inputContainerFinanciero {
  width: 100%;
  display: flex;
  padding: 10px 0px;

  @media only screen and (min-width: 1024px) {
    width: 50%;
    justify-content: flex-end;
  }
}

.inputContainerHalfNoPadding {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 1024px) {
    width: 50%;
  }
}

.inputContainerHalfThree {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 1024px) {
    width: 30%;
  }
}

.inputContainerFinancieroOtros {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1370px) {
    width: 50%;
    justify-content: flex-start;
  }
}

.inputContainerBeneficiarios {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1370px) {
    flex-direction: row;
  }
}

.inputContainer16 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1370px) {
    width: 16%;
  }
}

.inputContainerFinancierofifth {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1370px) {
    width: 20%;
  }
}

//Seguros existentes
.inputContainerSeguros {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
  }
}

.inputContainerSegurosInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1025px) {
    width: 30%;
  }
}

.inputContainerSegurosInput60 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1025px) {
    width: 60%;
  }
}

.inputContainerSeguros17 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1025px) {
    width: 17%;
  }
}

//Medica
.inputContainerQuarterMedica {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 1025px) {
    width: 25%;
    // flex-direction: row;
  }
}

.inputGeneralContainerMedica {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
  }
}

.inputContainerMedica {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.inputContainerMedicaEnfermedades {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.inputContainerMedicaEnfermedadesLabel {
  width: 100%;
  padding: 10px 0px;

  @media only screen and (min-width: 769px) {
    width: 90%;
    padding-bottom: 20px;
  }
}

.inputContainerMedicaRespuesta {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.inputContainerMedicaLiteral {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 768px) {}
}

.inputContainerMedicaLiteralTitulo {
  width: 100%;
}

.inputContainerMedicaLiteralRespuesta {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 768px) {}
}

//Estilo vida
.inputContainerEstiloVida {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.inputContainerEstiloVidaLabel {
  width: 100%;
  display: flex;

  @media only screen and (min-width: 768px) {
    width: 90%;
  }
}

.inputContainerEstiloVidaRespuesta {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.inputContainerEstiloVidaNumeral {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (min-width: 768px) {}
}

.inputContainerEstiloGeneralPreguntas {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainerEstiloDeporte {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

//decalracion politica
.inputContainerDeclaracion {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1170px) {
    flex-direction: row;
  }
}

.inputContainerDeclaracionSecond {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1170px) {
    width: 33%;
  }
}

//conyuge
.inputContainerQuarterConyugue {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  @media only screen and (min-width: 1024px) {
    width: 25%;
  }
}

.inputContainerConyugue {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

//botones
.buttonPendiente {
  cursor: pointer;
  padding: 1em 3em;
  background-color: #0F265C !important;
  color: white;
  border-radius: 0.25em;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  margin-right: 20px;
  margin-bottom: 15px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
}

.buttonFinalizar {
  cursor: pointer;
  padding: 1em 3em;
  background-color: #0F265C !important;
  color: white;
  border-radius: 0.25em;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  //margin-bottom: 15px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
}

.buttonFinalizarDisabled {
  cursor: not-allowed;
  pointer-events: none;
  padding: 1em 3em;
  background-color: #3c4761 !important;
  color: rgb(150, 150, 150);
  border-radius: 0.25em;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  //margin-bottom: 15px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;
  }

}


.containerButtons {
  padding: 15px 0px;
  margin: 0px 15px;
}

//Fromulario debito
.containerTarjeta {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.containerButtonesDebito {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (min-width: 768px) {
    // width: 50%;
    flex-direction: row;
  }
}

.buttonGuardarDebito {
  cursor: pointer;
  padding: 1em 3em;
  background-color: #0f265c;
  color: white;
  border-radius: 0.25em;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  text-decoration: none;

  // @media only screen and (min-width: 768px) {
  //   width: 50%;
  //   flex-direction: row-reverse;
  // }
}

.containerRegresar {
  padding-bottom: 15px;

  @media only screen and (min-width: 768px) {
    // width: 50%;
    padding-bottom: 0px;
  }
}

.containerGuardar {
  margin: 0px;

  @media only screen and (min-width: 768px) {
    // width: 50%;
    margin: 0px 10px;
  }
}

.circle {
  height: 25px;
  width: 25px;
  background-color: #10265C;
  border-radius: 50%;
  display: inline-block;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.textStep {
  color: #0F265C;
  display: inline-block;
  counter-increment: step;
  text-align: center;
  // margin-right: 2.44140625em;
  padding-top: 10px;
  font-size: 0.8em;
}

.bigger-radio {
  position: absolute;
  width: 25px;
  height: 25px;
}

.bigger-radio-label {
  display: inline-block;
  padding: 0px 20px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.inputContainerAutorizacionDebito {
  width: 100%;
  padding: 10px 0px;

  @media only screen and (min-width: 769px) {
    width: 90%;
    padding-bottom: 20px;
  }
}

.radio-button-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 1em 3em;
  background-color: #F7F7F8;
  box-shadow: inset 0px 3px 3px #00000007;
  color: 10265C;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
}

.radio-button-label input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #595d68;
  outline: none;
  padding: 5px;
}

.radio-button-label input[type="radio"]:checked {
  background-color: #10265C;
}

@media only screen and (min-width: 768px) {
  .radio-button-label {
    margin-bottom: 0px;
  }
}

.horizontal-divider {
  height: 2px;
  background-color: #10265C;
  width: 100%;
  margin: 0 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  /* Fondo semitransparente */
}

.modal-content {
  background-color: #fefefe;
  margin: 1rem;
  padding: 20px;
  border: 1px solid #888;
  width: auto;
  /* or a specific width if you want */
  max-width: 90vw;
  max-height: 90vh;
  /* 80% of the viewport height */
  overflow-y: auto;
  /* enables vertical scrolling */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-content ol {
  list-style-type: decimal;
  /* Asegura que los números se muestren */
  padding-left: 20px;
  /* Agrega espacio para los números */
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  /* Size of the button */
  height: 30px;
  /* Size of the button */
  background-color: #10265C;
  /* Blue background */
  color: white;
  /* White text color */
  border-radius: 50%;
  /* Make it round */
  font-size: 20px;
  /* Size of the 'X' */
  font-weight: bold;
  /* Make 'X' bold */
  line-height: 30px;
  /* Center 'X' vertically */
  text-align: center;
  /* Center 'X' horizontally */
  border: none;
  /* No border */
  position: absolute;
  top: 10px;
  right: 10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

// In globalStyle.module.scss
.liTextSize {
  font-size: 22px; // You can adjust this value as needed
}

.emailLink {
  color: #007bff; // Color azul para el enlace
  text-decoration: underline; // Sin subrayado
  font-weight: bold; // Texto en negrita

  &:hover {
    color: #0056b3; // Color más oscuro cuando se pasa el ratón por encima
    text-decoration: underline; // Subrayado al pasar el ratón
  }

  &:active {
    color: #004085; // Color al hacer clic en el enlace
  }

  &:visited {
    color: #6f42c1; // Color para enlaces visitados
  }
}

.lightBluebg {
  height: 25px;
  width: 25px;
  background-color: #10265C;
  border-radius: 50%;
  display: inline-block;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.verticalDivider {
  border-left: 2px solid #000000;
  height: 75px;
  align-self: center;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.rowTitle {
  font: normal normal bold 25px/25px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  align-self: center;
}

.rowBody {
  font: normal normal normal 25px/25px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  align-self: center;
}

.loadingDualRing {
  margin: auto;
  width: 11%;
  padding: 10px;
}

.loadingDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0f265c;
  border-color: #0f265c transparent #0f265c transparent;
  animation: loadingDualRing 1.2s linear infinite;
}

@keyframes loadingDualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.downloadButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  /* Tamaño del botón */
  height: 50px;
  /* Tamaño del botón */
  background-color: #0F265C;
  /* Color de fondo */
  color: white;
  /* Color del ícono */
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
  text-decoration: none;
}

.downloadButton:hover {
  background-color: #0D1E48;
  /* Color de fondo al pasar el ratón por encima */
}