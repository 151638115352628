.ldsDualRing {
  margin: auto;
  width: 11%;
  padding: 10px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0f265c;
  border-color: #0f265c transparent #0f265c transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

label {
  color: #0F265C;
}

.input {
  padding: 5px 8px;
  min-height: 53px;
  background: #F7F7F8 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 3px #00000007;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
}

.submit {
  background: #0F265C 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  color: #FFFFFF;
  text-align: center;
  width: 204px;
  height: 60px;
  cursor: pointer;
  font: normal normal bold 20px/20px Raleway;
}

.submit:hover {
  background: #00369C 0% 0% no-repeat padding-box;
}

.check input[type=checkbox] {
  width: 25px;
  height: 25px;
  accent-color: #00369C;
}

.title {
  font: normal normal bold 25px/45px Raleway;
  letter-spacing: 0px;
  color: #0F265C;
  opacity: 1;
  padding-bottom: 25px;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bordesInputs {
  padding: "2px 8px";
  background-color: "hsl(0, 0%, 100%)";
  border-color: "hsl(0, 0%, 80%)";
  border-radius: "4px";
  border-style: "solid";
  border-width: "1px";
  cursor: "default";
  display: "flex";
  min-height: "38px";
  outline: "0!important"
}

.blockMenu {
  @media only screen and (min-width: 768px) {
    width: 80%;
    height: 25%;
    background: #0f265c;
    position: fixed;
    left: 76.16px;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    border-radius: 20px 20px 0px 0px;
    z-index: 0;
  }

  @media only screen and (min-width: 992px) {
    height: 50%;
  }
}

.boxContainer {
  width: 100%;
  height: 250px;

  @media only screen and (min-width: 768px) {
    z-index: 1;
    height: 350px;
    width: 400px;
    font-size: 25px !important;
  }
}

.fileInput {
  color: white !important;
  background-color: white !important;
}

.imgRounded {
  border-radius: 40px;
  width: 65%;
}

.myQuotesSubtitle {
  font-size: 32px !important;
  color: #0f265c !important;
  font-weight: 800 !important;
  margin-top: 150px !important;
}

.myQuotesSubtitleTable {
  font-size: 32px !important;
  color: #0f265c !important;
  font-weight: 800 !important;
  margin-top: 50px !important;
}

.myQuotesText {
  font-size: 22px !important;
  color: #0f265c !important;
  font-weight: 800 !important;
  margin-top: 10px !important;
}

.mainContainer {
  height: 87vh;
}

.mainContainerPriori {
  height: 80vh;

}

.btnMyQuotes {
  display: flex;
  justify-content: right;
  padding-right: 11vh;
  min-width: 100px;
}