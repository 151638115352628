@import 'components/global.scss';

.salesForce {

}

.textTitle{
  font-size: 28px;
  color: #0f265c;
  margin: 10px 0px;
  font-weight: 500;
}

.section {
  @media only screen and (min-width: 768px) {
    padding: 0px 100px;
  }
}

.cardContainerLeft {
  background-color: #eaf7fb;
  height: 95%;
  margin-right: 10px;
  padding: 40px 50px;
  @media only screen and (min-width: 768px) {
    height: 100%;
  }
}

.cardContainerRight {
  background-color: #eaf7fb;
  height: 47.5%;
  margin-left: 10px;
}

@media only screen and (min-width: 1444px) {
  .mainContainer {
    padding: 0px 175px;
  }
}

@media only screen and (min-width: 1600px) {
  .mainContainer {
    padding: 0px 275px;
  }
}

.mainContainer {
  height: 85vh;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.centerCard {
  height: 60%;
  width: 40%;
}

.leftCard {
  height: 60%;
  @media only screen and (min-width: 768px) {
    width: 40%;
  }
}

.rightCard {
  height: 60%;
  @media only screen and (min-width: 768px) {
    width: 60%;
  }
  
}

.subtitle{
  font-size: 24px;
  font-weight: 700;
  color: #0f265c;
  margin: 10px 0px;
}

.divider{
  height: 2px;
  background-color: #0f265c;
  margin: 15px 0px;
}

.subcontainerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.subcontainerRight {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.subContainerInsideCardLeft {
  width: 50%;
}

.subContainerInsideCardRight {
  width: 40%;
  display: flex;
  align-items: flex-end;
  height: 50%;
}

.centerCard {
  width: 100%;
}

.centerCardInside {
  @media only screen and (min-width: 768px) {
    display: flex;
  }
}

.centerCardInsideLeft {
  display: flex;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.centerCardInsideRight {
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.imgPadding {
  @media only screen and (max-width: 768px) {
    margin: 1rem auto auto;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 25px;
  }
}

.imgRounded {
  border-radius: 20px;
}

.buttonPosition {
  position: relative;
  z-index: 3;
  left: 90px;
  margin-top: -75px;
}
