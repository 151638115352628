@import 'components/global.scss';

.base {

  position: absolute;
  top: 15%;
  left: 3%;
  width: $scale1;
  height: $scale1;
  // transform: translateY(-50%);

}

.insideButton {

  left: $scale1;

}

.fill {

  fill: white;
  
}

.alignLeft {

  left: 0;
  right: auto;

}

.alignRight {

  left: auto;
  right: 0;

}

.slideRight {
  width: 100%;
  height: 100%;
	transition: 1s all;
}

.slideRight:hover {
  
	transform: translateX(75%);
}
