@import 'components/global.scss';

.loading {

  opacity: 0.5;

}

.button {

  width: 48%;
  margin-right: 2%;

  &:last-child {

    margin-right: 0;

  }
}

.space {
  margin:  30px 0px;
}

