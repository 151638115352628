@font-face {
  font-family: Foobar;
  src:
    url("../fonts/Raleway-VariableFont.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: Foobar;
  src:
    url("../fonts/Roboto-Light.ttf") format("truetype");
  /* font-weight: 300; */
  unicode-range: U+30-39;
}

* {
  font-family: Foobar !important;
}