@import 'components/global.scss';

.title {

  font-size: 2.5em;
  font-weight: 600;
  color: #0f265c;
  text-align: center;
  padding-bottom: 50px;

}
