@import 'components/global.scss';

.user {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $scale2;
  z-index: 10;

}

.name {

  float: right;
  opacity: 0.5;
  font-size: $scale-2;
  font-weight: 400;

}

.logos {
  display: none;

  @media (min-width: 768px) {

    display: flex;
    justify-content: space-evenly;

  }

}