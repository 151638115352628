@import 'components/global.scss';

.onboarding {

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background-color: transparent;
  // background-attachment: fixed;
  // background-image: radial-gradient(closest-corner, #A992E6, #6363AC);

}

.wrapper {

  height: $scale6; 
  // background-image: radial-gradient(ellipse at top, #A992E6, #6363AC);

}

.nav {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  a {

    color: white;
    margin-right: $scale;
    text-decoration: none;

    &:last-child {

      margin-right: 0;

    }
  }
}

.logo {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);

}

.button {

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #96d4e9;
  height: 60px;
  width: 240px;
  border-radius: 0px 0px 30px 30px;

}

.textButton {
  padding-left: 15px;
  font-weight: 800;
  color: #10265c;
}

.icon {

  margin-left: 15px;;
}

.background {
  background: rgb(16,38,92);
  background: linear-gradient(0deg, rgba(16,38,92,1) 0%, rgba(16,38,92,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}