@import 'components/global.scss';

.inRow {
  display: flex;
  align-items: center;
}

.background {
  background-color: #0F265C;
  color: white;
  height: 20px;
  width: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 35px;
    width: 60px;
  }
}

.background:hover {
  .arrow {
    // transform: translateX(250%);
    // -webkit-transition: 0.3s ease-in-out;
    -webkit-animation: arrow 0.5s ease-in-out both;
    animation: arrow 0.5s ease-in-out both;
  }
}

@-webkit-keyframes arrow {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-125%);
    transform: translateX(-125%);
  }
}

@keyframes arrow {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-125%);
    transform: translateX(-125%);
  }
}

.backButton {
  color: #0f265c;
  font-weight: 700;
  padding-left: 15px;
}