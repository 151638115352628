@import 'components/global.scss';

.ldsDualRing {
    margin: auto;
    width: 11%;
    padding: 10px;
  }
  
  .ldsDualRing:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #0f265c;
    border-color: #0f265c transparent #0f265c transparent;
    animation: ldsDualRing 1.2s linear infinite;
  }
  
  @keyframes ldsDualRing {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

.tg {
	border-collapse: collapse;
	border-spacing: 0;

	td {
		border-color: black;
		border-style: solid;
		border-width: 1px;
		font-family: Arial, sans-serif;
		font-size: 14px;
		overflow: hidden;
		padding: 10px 5px;
		word-break: normal;
	}

	th {
		border-color: black;
		border-style: solid;
		border-width: 1px;
		font-family: Arial, sans-serif;
		font-size: 14px;
		font-weight: normal;
		overflow: hidden;
		padding: 10px 5px;
		word-break: normal;
	}

	.tg73oq {
		background-color: #f7f7f8;
		border-color: white;
		border-style: solid;
		border-width: 3px;
		text-align: center;
		vertical-align: top;
		font-weight: 600;
		color: #0f265c;
	}

	.tg73oqWhite {
		background-color: transparent;
		border-color: white;
		border-style: solid;
		border-width: 3px;
		text-align: center;
		vertical-align: top;
	}

	.tg0pky {
		border-color: white;
		text-align: right;
		vertical-align: top;
		border-bottom-color: #eaecf6;
		width: 105px;
	}

	.tg0pkyHead {
		border-color: white;
		text-align: right;
		vertical-align: top;
		color: #0f265c;
		font-weight: 600;
	}
}