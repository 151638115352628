/* colours */
$c-bg: #ffffff;
$c-text: #0f265c;
$c-text-light: #64748b;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #73B0F4;
$c-dark: #1f2937;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #d95565;
$c-green: #10b981;
$c-blue: #00369c;
$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-darkpurple: #556CB6;
