@import 'components/global.scss';

.base {

  // position: relative;  
  z-index: 1;
  // display: flex;
}

.label {

  padding: $scale 0 $scale 0;
}

.input {

  padding: $scale;
  border: 1px solid black;
  background-color:#f4f4f4;
  width: 100%;
  // height: $scale
}