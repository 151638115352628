@import 'components/global.scss';

.label {

  text-transform: none !important;

}

/* Switch */
.switch {
  display: inline-block;
  span {
      position: relative;        
      width: 60px;
      height: 34px;
      float: left;
      input {
          display:none;
          &:checked + .slider { background-color: #00c745 !important; }
          &:checked + .slider:before { transform: translateX(26px); }
          &:focus + .slider { box-shadow: 0 0 1px #00c745 !important; }
      }
  }
  label {
      line-height: 34px;
      margin-left: 12px;
      cursor: pointer;
  }
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  outline: none;
  background-color: #9fa0a7 !important;
  transition: .4s;
  border-radius: 34px;
  cursor: pointer;
  &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
  }
}
