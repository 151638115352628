@import 'components/global.scss';

.footer {

  background-color: #0f265c;
  border-top: 1px solid $c-border;

}

.copyright, .address {

  display: block;
  clear: both;
  opacity: 0.7;
  text-align: left;
  font-style: normal;
  font-size: 0.8em;

}

.copyright {

  margin-bottom: $scale1;

}

.nav {

  margin-bottom: $scale;

}

.link {

  color: white !important;
  font-size: 0.9em;
  text-decoration: none !important;
  margin-right: $scale1;
  padding: 10px 0px;

}

.sections {
  display: flex;
  flex-direction: column;
}

.titleSection {
  color: #7adbd4;
  font-size: 1.5em !important;
  font-weight: bold !important;
  padding-bottom: 20px;
}

.icons {
  display: flex;
  flex-direction: row;
}

.iconFooter {
  margin: 5px 20px 5px 0px;
}

.firstIcon {
  margin: 5px 20px 5px -15px;
}