@import 'components/global.scss';

.wrapper {

  height: $scale8;
  background-color: #0f265c !important;
  // background-image: radial-gradient(ellipse at top, #A992E6, #6363AC);

}

.nav {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  a {

    color: white;
    margin-right: $scale;
    text-decoration: none;

    &:last-child {

      margin-right: 0;

    }
  }
}

.logo {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);

}

.transparent {

  background-image: none;
  background-color: transparent;

}

.button {

  display: inline-block;

}