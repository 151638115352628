@import 'components/global.scss';

.link {

  display: block;
  color: $c-text !important;
  text-decoration: underline;
  margin-bottom: $scale;
  text-align: center;

}