@import 'components/global.scss';

.download {

  background-color: #00369c;
  color: white;
  padding: $scale $scale5;
  border-radius: $radius;
}

.envioOtp {
  background-color: #f0f0f0;
  height: 50px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}