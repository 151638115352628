@import 'components/global.scss';

.container {
  width: 100%;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 20px;
  justify-content: flex-start;
}

.container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  background: #001689;
  padding: 0 20px;
  height: 125px;
  justify-content: space-between;
}

.logo {
  height: 45px;
}

.container02 {
  flex: 0 0 auto;
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  background: #001689;
  color: white;
  height: 70px;
  padding: 10px;

  @media only screen and (min-width: 769px) {
    height: 50px;
  }
}

.text {
  align-self: flex-start;
  padding-top: var(64px);
  padding-left: var(32px);
  padding-right: var(32px);
}

.text001 {
  font-size: 10px;
}

.text002 {
  font-size: 10px;
}

.text003 {
  font-size: 10px;
}

.text004 {
  font-size: 10px;
}

.text006 {
  font-size: 10px;
}

.text007 {
  font-size: 10px;
}

.text008 {
  font-size: 10px;
}

.text009 {
  font-size: 10px;
}

.text010 {
  font-size: 10px;
}

.text011 {
  font-size: 10px;
}

.text012 {
  font-size: 10px;
}

.container03 {
  flex: 0 0 auto;
  width: 274px;
  height: 183px;
  display: flex;
  align-items: flex-start;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
  flex-direction: column;
  padding-bottom: var(32px);
  justify-content: center;
}

.text014 {
  width: 100%;
  font-style: normal;
  font-weight: 700;
}

.text017 {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  border-color: var(#D9D9D9);
  border-width: 1px;
}

.container04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(32px);
  padding-right: var(32px);
  flex-direction: column;
  justify-content: flex-start;
}

.text020 {
  font-weight: bold;
}

.text021 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
  text-transform: uppercase;
}

.container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  background: #F7F7F8;

  @media only screen and (min-width: 769px) {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 20%;
  }
}

.container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(32px);
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  background: #FAFAFA;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-bottom-width: 0px;

  @media only screen and (min-width: 769px) {
    // margin-top: 15px;
    // margin-bottom: 15px;
    width: 20%;
  }
}

.container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  background: #F7F7F8;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;

  @media only screen and (min-width: 769px) {
    // margin-top: 15px;
    // margin-bottom: 15px;
    width: 60%;
  }
}

.container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  background: #F7F7F8;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;


  @media only screen and (min-width: 769px) {
    // margin-top: 15px;
    // margin-bottom: 15px;
    width: 20%;
  }
}

.container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  background: #F7F7F8;
  flex-direction: column;
  justify-content: center;


  @media only screen and (min-width: 769px) {
    width: 20%;
  }
}

.container20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(32px);
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.container21 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  background: #FAFAFA;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;

  @media only screen and (min-width: 769px) {
    width: 30%;
  }
}

.container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 15px;
  border-color: white;
  border-width: 2px;
  background: #FAFAFA;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 769px) {
    width: 40%;
  }
}

.container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: baseline;
  padding-top: var(32px);
  justify-content: flex-start;
}

.container25 {
  flex: 0 0 auto;
  width: 60%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(32px);
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (min-width: 769px) {
    width: 30%;
  }
}

.text052 {
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  background: #001689;
  color: white;
  height: 50px;
  padding: 10px;
  border-right: 2px solid white;
}

.text05 {
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  background: #001689;
  color: white;
  height: 70px;
  padding: 10px;
}

.textPrima {
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  background: #001689;
  color: white;
  height: 70px;
  padding: 10px;

  @media only screen and (min-width: 769px) {
    height: 70px;
  }
}

.text053 {
  width: 100%;
  background-color: #FAFAFA;
  height: 50px;
  border-right: 2px solid white;
}

.text054 {
  width: 100%;
  background: #F7F7F8;
  height: 50px;
  border-right: 2px solid white;
}

.text055 {
  padding-left: var(32px);
}

.text056 {
  padding-left: var(32px);
}

.text057 {
  padding-left: var(32px);
}

.text058 {
  padding-left: var(32px);
}

.container26 {
  flex: 0 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-right: var(32px);
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    width: 70%;
  }
}

.textCultivo {
  // width: 80%;
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  align-items: baseline;
  padding-top: var(32px);
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    width: 50%;
  }
}

.text059 {
  width: 100%;
  height: auto;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  padding-left: var(32px);
  padding-right: var(32px);
  padding-bottom: var(32px);
  background-color: #D9D9D9;
}

.container27 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.text060 {
  padding-left: var(32px);
}

.text061 {
  padding-left: var(96px);
  text-transform: uppercase;
}

.text062 {
  padding-left: var(32px);
}

.text063 {
  padding-left: var(32px);
}

.text064 {
  padding-left: var(32px);
}

.text065 {
  padding-left: var(32px);
}

.text066 {
  padding-left: var(32px);
  text-transform: uppercase;
}

.container28 {
  width: 100%;
  //height: 309px;
  display: flex;
  align-items: flex-start;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
  flex-direction: column;
  justify-content: flex-start;
}

.displayFlex {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.text067 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  background-color: #D9D9D9;
}

.container29 {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  border-color: #D9D9D9;
  border-width: 1px;
  justify-content: center;
  border-bottom-width: 0px;
}

.container30 {
  flex: 0 0 auto;
  width: 100%;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #F7F7F8;
  border-right: 2px solid white;
  flex-direction: column;
}

.container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  padding: 1%;
  align-items: center;
  justify-content: flex-start;
  background: #F7F7F8;
}

.container31 {
  flex: 0 0 auto;
  width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.text070 {
  font-style: normal;
  font-weight: 700;
}

.container32 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.text072 {
  font-style: normal;
  font-weight: 700;
}

.container33 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.text074 {
  font-style: normal;
  font-weight: 700;
}

.container34 {
  flex: 0 0 auto;
  width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-bottom-width: 0px;
}

.text076 {
  font-style: normal;
  font-weight: 700;
}

.container35 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container36 {
  flex: 0 0 auto;
  width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
}

.text078 {
  font-style: normal;
  font-weight: 700;
}

.container37 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
}

.text080 {
  font-style: normal;
  font-weight: 700;
}

.container38 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
}

.text082 {
  font-style: normal;
  font-weight: 700;
}

.container39 {
  flex: 0 0 auto;
  width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
}

.text084 {
  font-style: normal;
  font-weight: 700;
}

.container40 {
  flex: 0 0 auto;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    width: 100%;
  }
}

.containerDetalle {
  flex: 0 0 auto;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: 425px) {
    width: 280px;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }
}

.containerPrimaTotal {
  flex: 0 0 auto;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // @media only screen and (min-width: 425px) {
  //   width: 280px;
  // }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }
}

.container41 {
  flex: 0 0 auto;
  width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text086 {
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.container42 {
  flex: 0 0 auto;
  width: 50%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container43 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container44 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(32px);
  padding-left: 12px;
  padding-right: var(32px);
  flex-direction: column;
  justify-content: center;
}

.container45 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  background-color: #f1f0ef;
}

.container46 {
  flex: 0 0 auto;
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.text089 {
  font-style: normal;
  font-weight: 700;
}

.container47 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.text090 {
  font-style: normal;
  font-weight: 700;
}

.container48 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f9f7f7;
}

.container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f9f7f7;
}

.container49 {
  flex: 0 0 auto;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container50 {
  flex: 0 0 auto;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container51 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.container52 {
  flex: 0 0 auto;
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text093 {
  width: 100%;
  text-align: center;
}

.text097 {
  width: 100%;
  text-align: center;
}

.container53 {
  flex: 0 0 auto;
  width: 200px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text099 {
  width: 100%;
  text-align: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.text100 {
  width: 100%;
  text-align: right;
  padding-right: 50px;
}

.text101 {
  width: 100%;
  text-align: right;
  padding-right: 50px;
}

.text103 {
  width: 100%;
  text-align: center;
  border-color: var(#D9D9D9);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.container54 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
}

.text105 {
  text-align: center;
}

.container55 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  background-color: #f9f7f7;
  padding: 15px;
  justify-content: flex-start;
}

.container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  font-weight: bold;
  align-items: flex-start;
  background-color: #f9f7f7;
  padding: 15px;
  padding-bottom: 0px;
  justify-content: flex-start;
}

.container56 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
  justify-content: flex-start;
}

.text107 {
  font-style: normal;
  font-weight: 700;
}

.container57 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(32px);
  padding-right: var(32px);
  justify-content: flex-start;
}

.container58 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
  justify-content: flex-start;
}

.text109 {
  font-style: normal;
  font-weight: 700;
}

.container59 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(32px);
  padding-right: var(32px);
  justify-content: flex-start;
}

.container60 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(32px);
  padding-left: var(32px);
  padding-right: var(32px);
  justify-content: flex-start;
}

.text111 {
  font-style: normal;
  font-weight: 700;
}

.container61 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(32px);
  padding-right: var(32px);
  justify-content: flex-start;
}