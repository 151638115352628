@import 'components/global.scss';

.steps {

  list-style: none;
  text-align: center;
  counter-reset: step;

  li {

    display: inline-block;
    counter-increment: step;
    text-align: center;
    font-size: 0.8em;
    @media only screen and (min-width: 768px) {
      margin-right: $scale4;
    }

    a {

      color: #0F265C;
      padding-top: 3em;
      text-decoration: none;

    }

    &:before {

      content: counter(step);
      display: block;
      color: white;
      width: $scale3;
      height: $scale3;
      margin: 0 auto;
      border-radius: 100%;
      text-align: center;
      font-weight: 700;
      font-size: $scale1;
      line-height: 2em;
      background: lighten($c-primary, 4%);
      margin-bottom: 0.5em;

    }

    &:last-child {

      margin-right: 0;

    }
  }
}

.complete:before {

  background: #00369C 0% 0% no-repeat padding-box!important;
  opacity: 1;

}

.completeHide {
  display: none !important;
  a{
    display: none !important;
  }

}

.textItem{
  width: 200px;
  height: 50px;
  color: #0F265C;
}

.textItemHidden{
  display: none;
}
