@import 'components/global.scss';

.base {

  // text-transform: uppercase;
}

.whiteTitle {

  padding: $scale2 $scale1;
  margin-bottom: $scale;
  font-weight: 800;
  background-color: #95d4e9;
  // color: white;
}

.blackTitle {

  padding: $scale $scale $scale $scale ;
  margin-bottom: $scale;
  background-color: #0f265c;
  color: white;
}